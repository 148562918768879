<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxies Targeted To Campaigns</h2>


        <div style="background-color: white; padding:2%;">
            <div class="form-row">
                <div class="form-group col-md-2">

                    <input type="date" v-model="filter.from" class="form-control date" id="dateOne" placeholder="From"/>
                </div>
                <div class="form-group col-md-2">

                    <input type="date" v-model="filter.to" class="form-control date" id="dateTwo" placeholder="To"/>
                </div>
                <div class="form-group col-md-2">


                    <select v-model="filter.status" class="form-control">
                        <option value="" selected>-- Select Status --</option>
                        <option value="active">Active</option>
                        <option value="disabled">Disabled</option>
                    </select>

                </div>
                <div class="form-group col-md-2">

                    <select v-model="filter.provider" class="form-control">
                        <option selected value="">--Any Provider--</option>
                        <option value="didsoft">DidSoft</option>
                        <option value="crawlera">Crawlera</option>
                        <option value="flipnode">FlipNode</option>
                        <option value="homeip">HomeIp</option>
                        <option value="luminate">Luminate</option>
                        <option value="proxyrack">Proxyrack</option>
                        <option value="smartproxy">Smartproxy</option>
                        <option value="netnut">Netnut</option>
                        <option value="proxyguys">ProxyGuys</option>
                        <option value="spider">Spider</option>
                    </select>
                </div>

                <div class="text-center pb-4">

                    <button
                        type="button"
                        class="btn text-white mr-1"
                        @click="search"
                        style="background-color: #383838"
                    >
                        <i
                            class="fa fa-search"
                            title="Search"
                        >
                        </i>
                        Search
                    </button>
                    <button
                        type="button"
                        class="btn btn-success mr-1"
                        v-if="showExportButton"
                        @click="exportToExcel"
                    >
                        <i
                            class="fa fa-download"
                            title="Export"
                        >
                        </i>
                        Export
                    </button>
                </div>
            </div>
            <div class="report">
                <div class="row mb-12" v-if="reportLoading">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                                <h4>Processing..............</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        <h5>Proxy Targeting for State and National Proxies</h5>
                        <ejs-grid
                            ref="grid"
                            id="Grid"
                            :dataSource="report"
                            :allowPaging="true"
                            :allowExcelExport='true'
                            :pageSettings='pageSettings'
                            :filterSettings="filterSettings"
                            :allowSorting ='true'
                            :allowFiltering="true"
                            :allowGrouping="true"
                            :dataBound='dataBound'
                        >
                            <e-columns>
                                <e-column field='proxy_provider' headerText='Provider' ></e-column>
                                <e-column field='GBTargeted' headerText='GBs Used (Targeted)' details="'this is just is'" :template="ctIPsTemplate"></e-column>
                                <e-column field='GBUSED' headerText='GBs Used (Total)'></e-column>


                            </e-columns>

                        </ejs-grid>
                    </div>

                </div>


            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, Group} from "@syncfusion/ej2-vue-grids";
import  ctIPsTemplate from "@/components/ctIPsTemplate";
import pTemplate from "@/components/pTemplate";

import Vue from "vue";
import swal from "sweetalert";

Vue.use(GridPlugin);

export default {
    name: 'PTRreport',
    props:['user'],
    // components:{
    //     /*VSelect*/
    // },

    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, Group, ]
    },
    data: function () {
        return {

            items:[],
            campaign:null,

            filter:{
                to: '',
                from: '',
                status:'',
                provider:'',
                page:'',

            },
            Total:"",
            date:false,
            state:false,
            city:false,

            campaigns:[],
            states:[],
            source:[],
            cities:[],
            report:{ip:''},
            reportTwo:[],
            pageSettings: {pageSize: 25},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
            ctIPsTemplate: function () {
                return {
                    template : ctIPsTemplate,
                }
            },
            pTemplate: function () {
                return {
                    template : pTemplate
                }
            }



        }
    },
    created: function(){
        this.load();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    computed:{


    },
    methods: {

        dataBound: function () {

        },

        windowListener: function (e) {
            if (e.keyCode === 13 && !this.searching) {
                this.search();
            }
        },
        load: function () {
            //Load the campaign
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.loadStates();
            this.$root.preloader = false;
        },
        onSearch(search, loading) {
            loading(true);
            this.campaignSearch(loading, search, this);
        },
        campaignSearch: function (loading, search, vm) {
            //Load the campaign
            var request = {'apikey': vm.$root.apikey, filters: {}};
            request.query = search;

            axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`, {params: request}).then(function (resp) {
                vm.campaigns = (resp.data && resp.data.data) ? resp.data.data : null;
                loading(false);
            });
        },
        search: function () {

            if (new Date(this.filter.from) < this.getDateDifference()) {
                swal({title: 'Oops', text: `Out of range, please select dates within 2 months.`, icon: 'error'})
                return;
            }

            this.searching = true;
            this.showExportButton = false;
            this.reportLoading = true;
            this.report = [];
            this.proxieDataSet = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    from: new Date(),
                    to: new Date()
                }
            };

            request.filters.from = this.filter.from;
            request.filters.to = this.filter.to;


            if (this.filter.status) request.filters.status = this.filter.status;
            if (this.filter.provider) request.filters.provider = this.filter.provider;


            axios.get(`${this.$root.serverUrl}/admin/reports/proxy/campaign/targeted`, {params: request}).then(function (resp) {
                //Store the stats
                if (resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    const result = resp.data.data;
                    console.log(result)
                    if (result.length > 0) {
                        const tasks = [];
                        for (let i in result) {
                            tasks.push(this.stage(result[i]));
                        }
                        Promise.all(tasks).then(function () {
                            console.log(this.proxieDataSet)
                            for (const item in this.proxieDataSet) {
                                if (this.proxieDataSet[item]._id != null) {
                                    this.report.push( // add all the specified data to the report array
                                        {
                                            GBUSED: this.gbused(this.proxieDataSet[item].GBUSED),
                                            proxy_provider: this.proxieDataSet[item].proxy_provider,
                                            GBTargeted: this.proxieDataSet[item].GBTargeted,
                                            details: [this.proxieDataSet[item].details],
                                            filter: request

                                        })
                                }
                            }
                            this.showExportButton = true;
                            this.reportLoading = false;

                        }.bind(this));

                    } else {
                        this.reportLoading = false;
                    }
                }
                this.searching = false;
            }.bind(this)).catch((err) => {
                this.reportLoading = false;
                swal({
                    title: 'Oops',
                    text: `Sorry, I am unable to complete your request. ${err.message}`,
                    icon: 'error'
                })
            });
        },


        stage: function (dataset) {
            return new Promise(function (resolve) {
                if (!this.proxieDataSet.hasOwnProperty(dataset._id)) {
                    this.proxieDataSet[dataset._id] = dataset;
                }

                return resolve();
            }.bind(this));
        },
        gbused: function (a, b = 2) { // converts the bye to a another example Bytes to KB
            if (0 === a) return "0 Bytes";
            const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
            return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
        },

        loadStates: function(){
            this.states = [];
            var request = {
                'apikey': this.$root.apikey,
                filters:{
                    country_iso_code: 'US'
                }
            };
            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadCities: function(){
            this.cities = [];
            this.filter.city = "";
            var request = {'apikey': this.$root.apikey,filters:{}};

            request.filters.country_iso_code = 'US';
            if(this.filter.state) request.filters.subdivision_1_iso_code = this.filter.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to}_capped_and_shown_report`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        getDateDifference: function(){
            const toDate = new Date(this.filter.to);
            let fromDate = new Date(this.filter.to);
            //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
            fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
            return fromDate;
        },
        fixDates: function(){
            this.filter.from = this.getDateFormatted(this.getDateDifference());
        },
        BandCal :function (bytes) {
            var i = Math.floor(Math.log(bytes) / Math.log(1024)),
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
        },
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}

.d-center {
    display: flex;
    align-items: center;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
    color: #fff;
}
</style>
