<template>
    <div>
        <a @click="viewDetails" style="cursor: pointer; color: blue">{{ data.CampaignsCount }}</a>
        <b-modal
            v-model="modalShow"
            size="xl"
            scrollable
            cancel-disabled
            :title="'Campaigns That Used Targeted IPs'"
        >
            <div class="row">
                <div class="col-12 pt-3">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="report"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                        :filterSettings="filterSettings"
                        :allowSorting ='true'
                        :allowFiltering="true"
                        :allowGrouping="true"
                        :dataBound='dataBound'
                    >
                        <e-columns>
                            <e-column field='campaign_name' headerText='Campaign Name' ></e-column>
                            <e-column field='proxy_ip' headerText='IP Address'></e-column>
                            <e-column field='gbused' headerText='GBs Used'></e-column>
                        </e-columns>

                    </ejs-grid>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, Group} from "@syncfusion/ej2-vue-grids";


Vue.use(GridPlugin);
// Vue.use(TabPlugin);
export default {
    name: "targetedIPs",
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, Group, ]
    },
    data: function() {
        return {
            data: {},
            CampaignsCount:0,
            modalTitle:"Modal Title",
            modalShow:false,
        }
    }, mounted() {
        this.loading();
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Campaigns that used targeted IPs`;
            this.modalShow = true;
        },
        loading:function(){ //fills the report array with the needed data
            this.report = [];
            for(var i =0; i<this.data.details.length; i++){
                    this.report.push({proxy_ip:this.data.details[i].ip,
                        gbused:this.data.details[i].gbused,
                        campaign_name:this.data.details[i].name,
                    })
            }


        },
    }
}
</script>

<style scoped>

</style>
