<template>
    <div>
        <a @click="viewDetails" style="cursor: pointer; color: blue">{{ data.GBTargeted }}</a>
        <b-modal
            v-model="modalShow"
            size="xl"
            scrollable
            cancel-disabled
            :title="'GBs Used (Targeted)'"
        >
            <div class="row">
                <div class="col-12 pt-3">
                    <ejs-grid
                        ref="grid"
                        id="Grids"
                        :dataSource="finalreports"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                        :filterSettings="filterSettings"
                        :allowSorting ='true'
                        :allowFiltering="true"
                        :allowGrouping="true"
                        :actionBegin='actionBegin'
                    >
                        <e-columns>

                            <e-column field='provider' headerText='Proxy Provider'></e-column>
                            <e-column field='date' headerText='Date'></e-column>
                            <e-column field='ip' headerText='IP Address'></e-column>
                            <e-column field='city' headerText='City'></e-column>
                            <e-column field='CampaignsCount' headerText='Count Of Campaigns' :template="targetedIPs"></e-column>
                            <e-column field='totalgbused' headerText='GBs Used'></e-column>
                        </e-columns>

                    </ejs-grid>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>

import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, Group} from "@syncfusion/ej2-vue-grids";

import targetedIPs from "@/components/targetedIPs";
import axios from "axios";
import swal from "sweetalert";

Vue.use(GridPlugin);
// Vue.use(TabPlugin);
export default {

    name: "targetedIPs",
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, Group, ]
    },

    data: function() {
        return {
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: {},
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            finalreports:[],
            modalTitle:"Modal Title",
            modalShow:false,
            pageSettings: {pageSize: 25},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            targetedIPs: function () {
                return {
                    template : targetedIPs,
                }
            },
        }
    },
    mounted() {

        // this.loading();
        this.getDetails(true);

        if(this.data.filter.filters.from) this.filter.from = this.data.filter.filters.from
        if(this.data.filter.filters.to) this.filter.to = this.data.filter.filters.to
        if(this.data.filter.filters.provider) this.filter.provider = this.data.filter.filters.provider
        if(this.data.filter.filters.status) this.filter.status = this.data.filter.filters.status

        this.data.GBTargeted =  this.gbused(this.data.GBTargeted)

    },
    methods:{
        actionBegin: function(args) {
            console.log(args);
            if(args.requestType === "paging") {
                this.filter.page = args.currentPage;
                this.getDetails(false);
            }
        },
        stage: function (dataset) {
            return new Promise(function (resolve) {
                if (!this.proxieDataSet.hasOwnProperty(dataset._id)) {
                    this.proxieDataSet[dataset._id] = dataset;
                }

                return resolve();
            }.bind(this));
        },
        getDetails:function ( count){
            this.report = [];
            this.proxieDataSet = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    from: new Date(),
                    to: new Date()
                }
            };

            request.filters.from = this.filter.from;
            request.filters.to = this.filter.to;
            request.count = count;

            if (this.filter.status) request.filters.status = this.filter.status;
            if (this.filter.provider) request.filters.provider = this.filter.provider;
            if (this.filter.page) request.filters.page = this.filter.page;

            // if(this.counterCheck == this.counerCheckTwo ) {
            axios.get(`${this.$root.serverUrl}/admin/campaigns/gbdatadetails`, {params: request}).then(function (resp) {
                //Store the stats
                let count = (resp.data) ? resp.data.count : 0;
                this.counterCheck = count
                console.log(count)
                if (resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    const result = resp.data.data;
                    if (result.length > 0) {
                        const tasks = [];
                        for (let i in result) {
                            tasks.push(this.stage(result[i]));
                        }
                        for (const item in this.proxieDataSet) {
                            if (this.proxieDataSet[item]._id != null) {
                                this.repo.push( // add all the specified data to the report array
                                    {
                                        id: this.proxieDataSet[item]._id,
                                        details: [...this.proxieDataSet[item].detail],
                                    })
                            }
                        }
                    }
                    //  loads the report array with the data needed to be grouped and parsed
                    if(this.repo.length > 0){
                        for(var i =0; i< this.repo.length; i++){
                            for(var x =0; x< this.repo[i].details.length; x++){
                                if(this.repo[i].id === this.data.proxy_provider){
                                    this.report.push({ip:this.repo[i].details[x].ip,
                                        provider:this.repo[i].details[x].provider,
                                        date: this.repo[i].details[x].date,
                                        city:this.repo[i].details[x].city,
                                        gbused:this.repo[i].details[x].gbused,
                                        name:this.repo[i].details[x].name,
                                        targeted:this.repo[i].details[x].targeted
                                    })
                                }

                            }
                        }
                    }

                    // /////parsed
                    // collects the data checks for duplicate send the duplicates to the details array
                    // and count the amount of duplicated data
                    this.reports  = [...this.report.reduce( (mp, o) => {
                        const key = JSON.stringify([o.ip,o.city]);
                        if (!mp.has(key)) mp.set(key, { ...o, CampaignsCount: 0,details:[],totalgbused:0, gbtargeted:0});

                        //test to see if the data has the targeted tag added to it
                        if(o.targeted=== true){ mp.get(key).gbtargeted += o.gbused;
                            mp.get(key).totalgbused += o.gbused;
                            mp.get(key).CampaignsCount++;
                            mp.get(key).details.push({name:o.name, ip:o.ip, gbused:this. gbused(o.gbused), targeted:o.targeted});
                        }
                        return mp;
                    }, new Map).values()];

                    //used to get the gb used parsed to show eg 4.6gb
                    if(count)   this.finalreports = []
                    for(var c =0; c<this.reports.length; c++){
                        // if not null show targeted
                        if(this.reports[c].targeted === true) // needed to filter out any extra data that is not targeted
                            this.finalreports.push({ip:this.reports[c].ip,
                                target:this.reports[c].targeted,
                                provider:this.reports[c].provider,
                                date:this.reports[c].date,
                                city:this.reports[c].city,
                                totalgbused:this.gbused(this.reports[c].totalgbused),
                                name:this.reports[c].name,
                                CampaignsCount: this.reports[c].CampaignsCount,
                                details:this.reports[c].details,
                                gbtargeted:this.reports[c].gbtargeted,
                            })
                    }

                    if( this.finalreports.length != 0)this.counerCheckTwo = this.finalreports.length
                }
                this.searching = false;
            }.bind(this)).catch(() => {
                this.reportLoading = false;
            });
            // }
        },
        viewDetails: function(){
            this.modalTitle = `GBs Used (Targeted)`;

            this.data.filter.filters.to? this.filter.to = this.data.filter.filters.to : 0,
                this.data.filter.filters.from? this.filter.from = this.data.filter.filters.from : 0,
                this.data.filter.filters.status? this.filter.status = this.data.filter.filters.status : 0,
                this.data.filter.filters.provider? this.filter.provider = this.data.filter.filters.provider : 0,

                this.getDetails(true);
            this.modalShow = true;

        },
        loading:function(){
            this.report = [];
            this.reports = [];
            this.finalreports = [];
            //loads the report array with the data needed to be grouped and parsed
            for(var i =0; i<this.data.details.length; i++){
                for(var x =0; x<this.data.details[i].length; x++){
                    this.report.push({ip:this.data.details[i][x].ip,
                        provider:this.data.details[i][x].provider,
                        date: this.data.details[i][x].date,
                        city:this.data.details[i][x].city,
                        gbused:this.data.details[i][x].gbused,
                        name:this.data.details[i][x].name,
                        targeted:this.data.details[i][x].targeted
                    })
                }
            }
            /////parsed
            // collects the data checks for duplicate send the duplicates to the details array
            // and count the amount of duplicated data
            this.reports  = [...this.report.reduce( (mp, o) => {
                const key = JSON.stringify([o.ip,o.city]);
                if (!mp.has(key)) mp.set(key, { ...o, CampaignsCount: 0,details:[],totalgbused:0, gbtargeted:0});

                //test to see if the data has the targeted tag added to it
                if(o.targeted=== true){ mp.get(key).gbtargeted += o.gbused;
                    mp.get(key).totalgbused += o.gbused;
                    mp.get(key).CampaignsCount++;
                    mp.get(key).details.push({name:o.name, ip:o.ip, gbused:this. gbused(o.gbused), targeted:o.targeted});
                }
                return mp;
            }, new Map).values()];

            //used to get the gb used parsed to show eg 4.6gb
            for(var c =0; c<this.reports.length; c++){
                // if not null show targeted
                if(this.reports[c].targeted === true) // needed to filter out any extra data that is not targeted
                    this.finalreports.push({ip:this.reports[c].ip,
                        target:this.reports[c].targeted,
                        provider:this.reports[c].provider,
                        date:this.reports[c].date,
                        city:this.reports[c].city,
                        totalgbused:this.gbused(this.reports[c].totalgbused),
                        name:this.reports[c].name,
                        CampaignsCount: this.reports[c].CampaignsCount,
                        details:this.reports[c].details,
                        gbtargeted:this.reports[c].gbtargeted
                    })
            }

        },
        gbused: function (a, b = 2) { // converts the bytes to for example kb etc
            if (0 === a) return "0 Bytes";
            const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
            return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
        },
    }
}
</script>

<style scoped>

</style>
