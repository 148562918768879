import { render, staticRenderFns } from "./ctIPsTemplate.vue?vue&type=template&id=92b4b484&scoped=true&"
import script from "./ctIPsTemplate.vue?vue&type=script&lang=js&"
export * from "./ctIPsTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92b4b484",
  null
  
)

export default component.exports